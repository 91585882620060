body, html{
  font-family: Arial, sans-serif;
  height: 100%;
  padding: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
 }

 button, a{
  &:active, &:focus{
    outline :none;
  }
 }
