.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 76.875em;
  position: relative;
}

.wrapper{
  background-color: $color--grey-1;
  min-height: 100%;
}

.main{
  background-color: white;
  display: block;
  overflow: auto;
  margin-top: 0;
}


@include breakpoint(large){
  .main{
    margin-left: 200px;
  }
}

.content{
  color: white;
  line-height: 1em;
  width: 100%;

  @include clearfix;

  p{
    font-size: 0.75em;
    margin-top: 0;
    margin-bottom: 1em;
  }

  ul{
    font-size: 0.75em;
    padding-left: 0;
    margin-top: 0;
    padding-top: 0;

    li{
      margin-bottom: 1em;
    }
  }

}

.grid{
  background-color: $color--grey-2;
  background-position: center;
  background-size: cover;
  display: block;
  float: left;
  min-height: 320px;
  text-decoration: none;
  padding: 1.5em;
  width: 100%;

  img{
    display: block;
    height: auto;
    width: 100%;
  }

  &.grid--no-padding{
    padding: 0;
  }

  &.grid--auto-height{
    min-height: 100px;
  }

  &.grey{
    background-color: $color--grey-1;
  }

  &.red{
    background-color: $color--red;
  }

  &.grey-1{
    background-image: url(../img/ui/block-grey-1.png);
  }

  &.grey-2{
    background-image: url(../img/ui/block-grey-2.png);
  }

  &.grey-dark-1{
    background-image: url(../img/ui/block-grey-dark-1.png);
  }

  &.grey-dark-2{
    background-image: url(../img/ui/block-grey-dark-2.png);
  }

  &.red-1{
    background-image: url(../img/ui/block-red-1.png);
  }

  &.red-2{
    background-image: url(../img/ui/block-red-2.png);
  }

&.joint-ventures-image{
    background-image: url(../img/ui/block-joint-ventures-img.jpg);
  }

  &.residential-image{
    background-image: url(../img/ui/block-residential-img.jpg);
  }

  &.investment-image{
    background-image: url(../img/ui/block-investment-img.jpg);
  }

  &.retail-image{
    background-image: url(../img/ui/block-retail-img.jpg);
  }

  &.leisure-image{
    background-image: url(../img/ui/block-leisure-img.jpg);
    background-position: bottom left;
  }

  &.offices-image{
    background-image: url(../img/ui/block-offices-img.jpg);
  }


  h2{
    color: white;
    margin-top: 0;
    text-align: right;
    text-transform: lowercase;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1em;
  }

}


.grid{
  background-color: $color--grey-2;

  &.latest-news{
    background-image: url(../img/ui/block-grey-1.png);
  }

  &.left{

    &.about{
      background-image: url(../img/ui/block-grey-1.png);
    }
    &.joint-ventures{
      background-image: url(../img/ui/block-joint-ventures.jpg);
    }
    &.offices{
      background-image: url(../img/ui/block-grey-2.png);
    }
    &.retail{
      background-image: url(../img/ui/block-grey-1.png);
    }
    &.leisure{
      background-image: url(../img/ui/block-grey-2.png);
    }
    &.investment{
      background-image: url(../img/ui/block-grey-1.png);
    }
  }


  &.right{
    &.retail{
      background-image: url(../img/ui/block-retail.jpg);
    }

    &.joint-ventures{
      background-image: url(../img/ui/block-joint-ventures.jpg);
    }

    &.residential{
      background-image: url(../img/ui/block-residential.jpg);
    }

    &.offices{
      background-image: url(../img/ui/block-offices-img.jpg);
    }

    &.leisure{
      background-image: url(../img/ui/block-leisure.jpg);
    }

    &.investment{
      background-image: url(../img/ui/block-investment.jpg);
    }

  }
}

.row--alt{
  .grid{
    background-color: $color--red;

    &.left{
      &.joint-ventures{
        background-image: url(../img/ui/block-red-1.png);
      }

      &.investment{
        background-image: url(../img/ui/block-red-1.png);
      }

      &.residential{
        background-image: url(../img/ui/block-red-1.png);
      }

    }

    &.right{
      &.find-us{
        background-image: url(../img/ui/block-map.jpg);
      }
    }

  }
}



@include breakpoint(medium){

  .grid{
    &.right{
    }

    &.left{

    }
  }



  .row--alt{
    .grid{
      &.right{

        &.residential{
         background-image: url(../img/ui/block-red-1.png);
        }
        &.find-us{
          background-image: url(../img/ui/block-red-2.png);
        }
        &.offices{
          background-image: url(../img/ui/block-red-1.png);
        }

      }

      &.left{
        &.joint-ventures{
          background-image: url(../img/ui/block-joint-ventures.jpg);
        }

        &.residential{
          background-image: url(../img/ui/block-residential.jpg);
        }

        &.investment{
          background-image: url(../img/ui/block-investment.jpg);
        }

      }
    }
  }
}


@include breakpoint(medium){
  .grid{
    height: 450px;
    padding: 2.5em;


    &.grid--auto-height{
      height: auto;
    }

    h2{
      font-size: 1.8em;
    }

  }

  .grid--half{
    width: 50%;
  }

  .lead--content{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    .grid{
      flex-grow: 1;
      height: auto;
      min-height: 450px;
    }
  }

}

@include breakpoint(large){
  .grid{
    h2{
      font-size: 2.25em;
    }
  }
}


.partners--list{
  background-color: $color--red;
  padding-top: 0;

  ul{
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

      li{
        margin: 0;
        margin-bottom: 0.5em;
        width: 100%;
      }

    ul{
      font-size: 1em;
    }

  }
}

@include breakpoint(small){
  .partners--list{
    background-image: url(../img/ui/partners-bg.png);
    background-size: 1030px 900px;
    background-position: left bottom;
    background-repeat: no-repeat;
    border-bottom: 4px solid white;
    padding-bottom: 3em;
    ul{
      margin: 0;
      li{
        float: left;
        width: 50%;
      }
      ul{
        margin: 0;
        padding-right: 1em;
        width: 100%;

        li{
          width: 100%;
        }
      }
    }
  }
}

@include breakpoint(medium){
  .partners--list{
    padding-bottom: 6em;

    ul{
      li{
        width: 50%;
        float: left;
      }

      ul{
        float: left;
        width:50%;
      }

    }
  }
}


.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
