$color--red:    #d13239;
$color--grey-1: #999999;
$color--grey-2: #b2b2b2;


$size--text-header: 42;
$size--text-nav: 16;
$size--text-base: 12;

$size--nav--width: 200;
$size--height-default: 450;

$size--breakpoint-sm: 480;
$size--breakpoint-md: 768;
$size--breakpoint-lg: 1024;
$size--breakpoint-max: 1230;



/**
 * 4. BREAKPOINTS
 * --------------
 */

 $bp--small: "(min-width: 30em)"; // 480
 $bp--medium: "(min-width : 48em)"; // 768
 $bp--large: "(min-width : 64em)"; // 1024




@mixin breakpoint($bp){
  @if $bp == small {
  @media #{$bp--small} { @content; }
  }
  @if $bp == medium {
  @media #{$bp--medium} { @content; }
  }
  @if $bp == large {
  @media #{$bp--large} { @content; }
  }
}

@mixin clearfix{
  &::after, &::before{
    content: "";
    display: table;
  }

  &::after{
    clear: both;
  }
}
