.nav{
  background-color: $color--grey-1;
  display: block;
  width: 100%;
}

.nav--brand{
  height: 60px;
  position: relative;
}

.nav--logo{
  position: absolute;
  z-index: 1000;
  display: block;
  padding-top: 0.5em;
  left: 1.5em;
  img{
    height: auto;
    width: 120px;
  }
}

.nav--toggle{
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 3.75em;
  padding: 0.875em;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.75em;
  z-index: 999;
}

.nav--toggle-bar{
  background-color: white;
  display: block;
  height: 3px;
  margin-left: 0;
  opacity: 1;
  position: absolute;;
  right: 1.5em;
  top: 1.75em;
  width: 2em;
  transition: all 0.25s ease-in-out;


  &.nav--toggle-bar-top{
    transform: translateY(-0.5em);
  }

  &.nav--toggle-bar-middle{
  }

  &.nav--toggle-bar-bottom{
    transform: translateY(0.5em);
  }

}

ul.nav--menu{
  background-color: $color--grey-1;
  top: 0;
  left: -250px;
  bottom: 0;
  position: absolute;
  display: block;
  list-style: none;
  margin: 0;
  opacity: 0;
  padding-top: 60px;
  padding-left: 0;
  padding-right: 0;
  width: 200px;
  z-index: 999;
  transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.25);


  li{
    text-transform: lowercase;
    line-height: 1.4em;

    a{
      color: white;
      display: block;
      padding: 0.5em 1.5em;
      text-decoration: none;
    }
  }
}

.main{
  &::after{
    content: "";
    visibility: hidden;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: all 0.5s ease-in-out;
  }
}


body.menu--open{
  // position:fixed;
  overflow: hidden;

  @media all and (orientation: landscape){
    position:relative;
  }

  ul.nav--menu{
    left: 0;
    opacity: 1;
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .main{
    &::after{
      visibility: visible;
      opacity: 0.75;
      transition: all 0.5s 0.25s ease-in-out;
    }
  }

  .nav--toggle-bar{
    transition: all 0.25s ease-in-out;

    &.nav--toggle-bar-top{
      transform: rotate(45deg);
    }

    &.nav--toggle-bar-middle{
      opacity: 0;
    }

    &.nav--toggle-bar-bottom{
      transform: rotate(-45deg);
    }

  }

}

@include breakpoint(large){
  .nav{
    position: absolute;
    width: 200px;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .nav--toggle{
    display: none;
  }

  .nav--brand{
    height: auto;
  }

  .nav--logo{
    padding-bottom: 1.75em;
    padding-top: 2.1em;
    position: relative;

    img{
      width: 151px;
    }
  }

  ul.nav--menu{
    position: relative;
    left: 0;
    top: auto;
    bottom: auto;
    display: block;
    opacity: 1;
    box-shadow: none;
    transition: none;
    padding-top: 0;

    li{
      a{
        padding: 0.5em 2.5em;
      }
    }

  }

  body.menu--open{
    position: relative;
    overflow: auto;

    .main{
      &::after{
        visibility: none;
      }
    }
  }
}
