.slides{
  background-color: $color--red;
  border-bottom: 4px solid white;
  position: relative;
  width: 100%;
}

.slides--item{
  position: relative;
  img{
    height: auto;
    width: 100%;
  }

  .slide--news{
    padding: 1em;
  }
}

.slick-dots{
  bottom: 0.25em;
  left: 0;
  list-style: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  right: 0;
  text-align: center;

  button{
    background-color: $color--grey-2;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 0.67em;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 0.67em;

    &:hover{
      background-color: $color--grey-1;
    }
  }

  li{
    display: inline-block;
    margin-left: 0.125em;
    margin-right: 0.125em;

    &.slick-active{
      button{
        background-color: $color--red;
      }
    }

  }
}

.slides--news{
  &::after{
    background-image: linear-gradient(135deg, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 50%);
    bottom: 50%;
    content: " ";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 800;
  }
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }


  a.slides--item{
    z-index: 900;
  }

  .slick-dots{
    display: none !important;
  }

}

@include breakpoint(large){
  .slides--news{
    &::after{
      display: none;
    }

    .slick-dots{
      display: block !important;
    }

  }
  .slides--item{
    overflow: hidden;

    &::before{
      background-image: linear-gradient(135deg, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 50%);
      bottom: 0;
      content: " ";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 800;
    }
    .slide--news{
      background-color: $color--red;
      border-top-right-radius: 2000px;
      border-top-left-radius: 2000px;
      position: absolute;
      bottom: 0;
      left: -10em;
      width: 30em;
      height: 15em;
      z-index: 900;

    }

    .slide--news-text{
      position: absolute;
      bottom: 2em;
      left: 13em;
      right: 5em;
    }

  }

  .slides--no-overlay{
    .slides--item{
      &::before{
        display: none;
      }
    }
  }
}
