.header{
  position: relative;
  width: 100%;

  h1{
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 1em;
    margin-top: 0;
    line-height: 1em;
    text-transform: lowercase;
  }

  &.header--single{
    background-color: $color--red;
    height: auto;
    padding: 1.5em;

    @include clearfix;

  }

}

.header--title{
  left: 1.5em;
  position: absolute;
  top: 1.5em;
  z-index: 900;
}

.header--overlay{
  .slides{
    &::after{
      background-image: linear-gradient(135deg, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 50%);
      bottom: 0;
      content: " ";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 800;
    }
  }
}

@include breakpoint(medium){
  .header{
    h1{
      font-size: 1.8em;
      font-weight: 300;
    }
  }

  .header--title{
    left: 2.5em;
    top: 2.25em;
  }

  .header--overlay{
    .slides{
      &::after{
        right: 50%;
      }
    }
  }


  .header{
    &.header--single{
      padding-top: 2.25em;
      padding-left: 2.5em;
      padding-right: 2.5em;

      .header--title{
        position: relative;
      }
    }
  }

}

@include breakpoint(large){
  .header{
    h1{
      font-size: 2.4em;
    }
  }
}
